/**
 * Set the scrollbar width when the page loads so we can use it for viewport width calculations.
 */

jQuery(($) => {
    'use strict'

    let get_scrollbar_width = () => window.innerWidth - document.documentElement.clientWidth
    let set_scrollbar_width_global = () => document.documentElement.style.setProperty('--scrollbar-width', get_scrollbar_width() + 'px')
    let set_scrollbar_width_half_global = () => document.documentElement.style.setProperty('--scrollbar-width-half', (get_scrollbar_width() / 2) + 'px')
    set_scrollbar_width_global()
    set_scrollbar_width_half_global()
    $(window).on('resize', function() {
        set_scrollbar_width_global()
        set_scrollbar_width_half_global()
    })
})