/**
 * Set any outside links to open in a new tab
 */
jQuery(($) => {
    'use strict'

	let anchor_links = document.querySelectorAll('a:not([target="_blank"])');

	for(let i = 0; i < anchor_links.length; i += 1) {
		if(anchor_links[i].host !== window.location.hostname) {
			anchor_links[i].setAttribute('target', '_blank');
			anchor_links[i].setAttribute('rel', 'nofollow');
		}
	}
})